import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'querystring';
import { logoutUser } from '../actions/user';
import LoadingScreen from '../common/LoadingScreen';
import MainContent from '../common/MainContent';

const LogoutContainer = ({location}) => {

    const from = queryString.parse(location.search.substr(1)).r;

    logoutUser(from);

    return (
      <MainContent>
          <LoadingScreen/>
      </MainContent>
    )
};

const mapDispatch = dispatch => ({
    logout: (from) => {
        dispatch(logoutUser(from));
    }
});

export default withRouter(connect(null, mapDispatch)(LogoutContainer));