import axios from 'axios';
import * as _ from 'lodash';
import {BASE_URL_RELIANCE as BASE_URL, getCookie} from './utilityService';

class UserService {

  constructor() {

    this.ax = axios.create({
      baseURL: BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${getCookie()}`,
      }
    });
    this.data = {};
  }

  auth() {
    let accessToken = getCookie();
    return this.ax.post(`${BASE_URL}/auth/affiliates`, null)
      .then(response => {
        let user = mapUser(response.data);
        user.credentials.accessToken = accessToken;
        return user;
      })
      .catch(e => {
        throw new UserServiceException(e);
      });
  }


}

export function mapUser(json) {
  return {
    firstName: _.get(json, 'data.user_info.first_name'),
    lastName: _.get(json, 'data.user_info.last_name'),
    emailAddress: _.get(json, 'data.user_info.email_address'),
    phoneNumber: _.get(json, 'data.user_info.phone_number'),
    affiliateCode:_.get(json, 'data.user_info.referral_code'),
    bankInfo:{
      bankId:_.get(json, 'data.bank_info.bank_id'),
      accountName:_.get(json, 'data.bank_info.account_name'),
      accountNumber:_.get(json, 'data.bank_info.account_number'),
      bankName:_.get(json, 'data.bank_info.bank_name')
    },
    affiliateInfo: {
      commissionRate: _.get(json, 'data.affiliate_info.commission_rate'),
      instagramHandle: _.get(json, 'data.affiliate_info.instagram_handle'),
      twitterHandle: _.get(json, 'data.affiliate_info.twitter_handle'),
      type: _.get(json, 'data.affiliate_info.type'),
      website: _.get(json, 'data.affiliate_info.website')
    },
    credentials: {
      accessToken: _.get(json, 'data.basic_info.access_token')
    },
    preferences: {
      allowNotifications: ""
    }
  }
}

function UserServiceException(e, _meta) {
  if (!e.response)
    e = {response: {status: 0, statusText: "Unknown Error", data: {message: "An Error Occurred"}}};
  this.code = e.response.status;
  this.message = e.response.data.message || e.response.statusText;
  this.meta = _meta;
}


export default new UserService();